import { BoFilterByDate } from '../../modules/back-office/models/bo-filter-by-date';
import { OrderLineItemDetail } from '../../modules/back-office/models/order-details';
import { AptPartnerships } from '../../modules/common/partnership/enums/apt-partnerships';
import { EgonRequest } from '../../modules/switch-in/order-entry/models/egon-request';
import { Indirizzo } from '../../modules/switch-in/order-entry/models/indirizzi';
import { FlowType, MacroFlowType } from '../../store/models/flow-type';
import { OrderEntryState, ProductInfo } from '../../store/models/order-entry-state';
import { Address, OrderEntryState_v2, Product } from '../../store/models/order-entry-state_v2';
import { AptCommodityType, AptCommodityTypeOfSale } from '../enums/apttus/apt-commodity-typeof-sale';
import { AptProductFamily } from '../enums/apttus/apt-product-family';
import { AptProductType } from '../enums/apttus/apt-product-type';
import { AptSalesProcess } from '../enums/apttus/apt-sales-process';
import { D365ChannelCode, REMOTE_CHANNELS } from '../enums/d365/d365-channel-code';
import { DestinationUse } from '../enums/shared/destination-use.enum';
import { DeepPartial } from '../interfaces/deep-partial';
import {
    ACTIVATION_SALES_PROCESSES,
    FlowTypeMode,
    FLOWTYPE_AMM_VIP_REC_CFG,
    MACRO_FLOW_TYPES,
} from '../map/flow-type-mapping.config';
import { EglProductExtended } from '../models/apttus/tables/product/egl-product-extended';
import { LegalFormType } from './../models/app/credit-check.request';
import { cleanObj } from './misc.functions';
import { flowTypeToAptSalesProcess, flowTypeToMacroFlowType } from './remap.functions';
import { getFullAddress } from './string-format.functions';

export type ProductTypeInput = string | AptProductType;

const containsProductTypes = (
    prods: ProductTypeInput | ProductTypeInput[],
    targetProductTypes: AptProductType | AptProductType[],
): boolean => {
    const targetProductTypesList = [].concat(targetProductTypes);
    return [].concat(prods).some((prodType) => targetProductTypesList.includes(prodType));
};

export const containsProductGas = (prods: ProductTypeInput | ProductTypeInput[]): boolean =>
    containsProductTypes(prods, [AptProductType.ProdottoCommodityGas, AptProductType.TariffaCommodityGas]);

export const containsProductSmartHome = (prods: AptProductType[]): boolean => {
    return [AptProductType.SmartHome].some((x) => prods?.indexOf(x) >= 0);
};

export const containsProductPower = (prods: ProductTypeInput | ProductTypeInput[]): boolean =>
    containsProductTypes(prods, [AptProductType.ProdottoCommodityLuce, AptProductType.TariffaCommodityLuce]);

export const containsProductComplex = (prods: ProductTypeInput | ProductTypeInput[]): boolean =>
    containsProductTypes(prods, [
        AptProductType.Complex_caldaia,
        AptProductType.Complex_comp_tecnica,
        AptProductType.Complex_condizionatori,
        AptProductType.Complex_pompe_calore,
        AptProductType.Complex_scaldacqua,

        AptProductType.Complex_sist_ric_elettrica,
        AptProductType.Sopralluogo,
    ]);

export const containsProductInsurance = (prods: ProductTypeInput | ProductTypeInput[]): boolean =>
    containsProductTypes(prods, [AptProductType.AssicurazioneAncillare, AptProductType.AssicurazioneIntermediata]);

export const containsProductInsuranceIntermediata = (prods: ProductTypeInput | ProductTypeInput[]): boolean =>
    containsProductTypes(prods, [AptProductType.AssicurazioneIntermediata]);

export const containsAncillaryInsurance = (prods: ProductTypeInput | ProductTypeInput[]): boolean =>
    containsProductTypes(prods, [AptProductType.AssicurazioneAncillare]);

export const containsProductMaintenance = (prods: ProductTypeInput | ProductTypeInput[]): boolean =>
    containsProductTypes(prods, AptProductType.Manutenzione);

export function isProductRcDanni(prodInfo: ProductInfo): boolean;
export function isProductRcDanni(product: Partial<Product>): boolean;
export function isProductRcDanni(product: ProductInfo & Product): boolean {
    return (
        /rc danni/i.test(product?.nome || product?.name) &&
        product?.productType === AptProductType.AssicurazioneIntermediata
    );
}

// TODO Non c'è ancora il prodotto in catalogo, da sistemare, in caso
export function isProductNoPensieriPerTe(prodInfo: ProductInfo): boolean;
export function isProductNoPensieriPerTe(product: Partial<Product>): boolean;
export function isProductNoPensieriPerTe(product: ProductInfo & Product): boolean {
    return (
        /nopensieri per te/i.test(product?.nome || product?.name) &&
        product?.productType === AptProductType.AssicurazioneIntermediata
    );
}
export function isMaintenanceCaldaia(product: OrderLineItemDetail): boolean {
    return /no problem caldaia/i.test(product?.productName);
}
export function isMaintenanceScaldaAcqua(product: OrderLineItemDetail): boolean {
    return /no problem scaldacqua/i.test(product?.productName);
}

// TODO Non c'è ancora il prodotto in catalogo, da sistemare, in caso
export function isProductNoPensieriCasa(prodInfo: ProductInfo): boolean;
export function isProductNoPensieriCasa(product: Partial<Product>): boolean;
export function isProductNoPensieriCasa(product: ProductInfo & Product): boolean {
    return (
        /nopensieri casa/i.test(product?.nome || product?.name) &&
        product?.productType === AptProductType.AssicurazioneIntermediata
    );
}

export const containsCommodity = (prods: AptProductType | AptProductType[]): boolean => {
    return containsProductGas(prods) || containsProductPower(prods);
};

export const containsGoodDiscounts = (prods: AptProductType | AptProductType[]): boolean => {
    return containsProductTypes(prods, [
        AptProductType.Sconti,
        AptProductType.ScontoStandAloneLuce,
        AptProductType.ScontoStandAloneGas,
        AptProductType.ScontoStandAloneExtracommodity,
    ]);
};

const LEGAL_FORM_TYPE_NOT_AZIENDA_CFG = [
    LegalFormType.Associazione,
    LegalFormType.Condominio,
    LegalFormType.Consorzio,
    LegalFormType.Cooperativa,
    LegalFormType.Ente,
    LegalFormType.Fondazione,
    LegalFormType.ImpresaIndividuale,
    LegalFormType.Onlus,
    LegalFormType.StudioAssociato,
];

export const isLegalFormTypeNotAzienda = (legalForm: LegalFormType): boolean =>
    LEGAL_FORM_TYPE_NOT_AZIENDA_CFG.includes(legalForm);

/**
 * @deprecated Use flowTypeUtil(flowType).inMacroFlowType(MacroFlowType.CambioProdotto)
 */
export const isFlowTypeChangeProduct = (flowType: FlowType): boolean =>
    (flowType || FlowType.SwitchIn).startsWith('CP_');

export const isFlowTypeChangeProductWithAssetLoader = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        FlowType.CPRetroattivoExNovo,
        FlowType.CPDelibera,
        FlowType.CPAmministrativo,
        FlowType.CPRetroattivoAssetCessato,
    );

export const isFlowTypeCPorCPREN = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(FlowType.CPCommerciale, FlowType.CPRetroattivoExNovo);

/**
 * @description Controlla se in InfoProdotti esistono commodity con l'opzione partnership attiva
 * @param products Le informazioni dei prodotti nel cart
 * @param commodityType Il tipo di commodity da controllare
 * @returns True => Esistono commodity del tipo indicato che hanno l'opzione partnership | false => Non esistono commodity del tipo indicato che hanno l'opzione partnership
 */
export const containsCommodityWithPartnership = (products: ProductInfo[], commodityType: AptCommodityType) => {
    const matrixTypes: { [key in AptCommodityTypeOfSale]: AptProductType[] } = {
        [AptCommodityTypeOfSale.Power]: [AptProductType.ProdottoCommodityLuce, AptProductType.TariffaCommodityLuce],
        [AptCommodityTypeOfSale.Gas]: [AptProductType.ProdottoCommodityGas, AptProductType.TariffaCommodityGas],
        [AptCommodityTypeOfSale.Dual]: [
            AptProductType.ProdottoCommodityLuce,
            AptProductType.TariffaCommodityLuce,
            AptProductType.ProdottoCommodityGas,
            AptProductType.TariffaCommodityGas,
        ],
    };

    return (
        (products || []).filter(
            (p) =>
                (matrixTypes[commodityType] || []).indexOf(p.productType) !== -1 &&
                p.partnership &&
                p.partnership.toUpperCase() !== AptPartnerships.NO_PARTNERSHIP,
        ).length > 0
    );
};
const ADMIN_VIP_MACRO_FLOW_TYPES = [MacroFlowType.Administrative, MacroFlowType.Vip];
const DISTINCT_MACRO_FLOW_TYPES: Exclude<MacroFlowType, MacroFlowType.Administrative | MacroFlowType.Vip>[] =
    Object.keys(MACRO_FLOW_TYPES).filter(
        ([mft]) => !ADMIN_VIP_MACRO_FLOW_TYPES.includes(mft as MacroFlowType),
    ) as Exclude<MacroFlowType, MacroFlowType.Administrative | MacroFlowType.Vip>[];

export function flowTypeUtil(flowType: FlowType) {
    type MFTAdminVip = MacroFlowType.Administrative | MacroFlowType.Vip | MacroFlowType.Recovery;

    type MFTCondition = MacroFlowType | [Exclude<MacroFlowType, MFTAdminVip | MFTAdminVip[]>, ...MFTAdminVip[]];

    return {
        /**
         * @description Compare the given flowType with a list of flowTypes, true if 1 of them match
         */
        equalTo: (...flowTypes: FlowType[]) => flowTypes.includes(flowType),

        /**
         * @description Compare the given flowType with a list of flowTypes ignoring mode, true if 1 of them match
         */
        equalIgnoreModeTo: (...flowTypes: FlowType[]) => {
            const comFlowType = flowTypeUtil(flowType).withMode(FlowTypeMode.Commercial);
            return flowTypes.some((ft) => flowTypeUtil(ft).withMode(FlowTypeMode.Commercial) === comFlowType);
        },

        /**
         * @description Check if one of the given macroFlowType conditions is valid: single MacroFlowType match or 1st MacroFlowType of the array and one of the rest
         */
        inMacroFlowTypes: (...orMacroFlowtypes: MFTCondition[]): boolean =>
            orMacroFlowtypes.some((andMacroFlowtypes) =>
                Array.isArray(andMacroFlowtypes)
                    ? flowTypeUtil(flowType).equalTo(...[].concat(MACRO_FLOW_TYPES[andMacroFlowtypes[0]] || [])) &&
                      (andMacroFlowtypes || [])
                          .slice(1)
                          .some((mft) => flowTypeUtil(flowType).equalTo(...[].concat(MACRO_FLOW_TYPES[mft])))
                    : flowTypeUtil(flowType).equalTo(...[].concat(MACRO_FLOW_TYPES[andMacroFlowtypes] || [])),
            ),

        /**
         * @description Check if there's a MacroFlowType which includes all the given flowTypes (initial and in this metod); NB: ADMIN & VIP MacroFlowTypes are not evaluated
         */
        hasSameMacroFlowType: (compareFlowType: FlowType) =>
            DISTINCT_MACRO_FLOW_TYPES.some(
                (macroFlowtype) =>
                    flowTypeUtil(flowType).equalTo(...[].concat(MACRO_FLOW_TYPES[macroFlowtype])) &&
                    flowTypeUtil(compareFlowType).equalTo(...[].concat(MACRO_FLOW_TYPES[macroFlowtype])),
            ),

        get mode(): FlowTypeMode {
            const targetCfg = FLOWTYPE_AMM_VIP_REC_CFG.find((cfg) =>
                flowTypeUtil(flowType).equalTo(...Object.values(cfg)),
            );
            const mode =
                (targetCfg &&
                    (Object.entries(targetCfg) as [FlowTypeMode, FlowType][]).find(
                        ([mode, ft]) => ft === flowType,
                    )?.[0]) ||
                null;
            return mode;
        },

        toSalesProcess: (): AptSalesProcess => flowTypeToAptSalesProcess(flowType),
        withMode: (flowTypeMode: FlowTypeMode): FlowType => {
            const targetCfg = FLOWTYPE_AMM_VIP_REC_CFG.find((cfg) =>
                flowTypeUtil(flowType).equalTo(...Object.values(cfg)),
            );
            return targetCfg?.[flowTypeMode] || flowType;
        },
        get macroFlowType(): MacroFlowType {
            return flowTypeToMacroFlowType(flowType);
        },
    };
}

/**
 * @deprecated use flowTypeUtil(flowType).inMacroFlowTypes([MacroFlowType.Voltura, MacroFlowType.Administrative, MacroFlowType.Vip])
 */
export const isAdministrativeVoltura = (flowType: FlowType, includeIncSoc: boolean = true): boolean =>
    flowTypeUtil(flowType).equalTo(
        ...cleanObj([
            FlowType.VolturaAmmVip,
            FlowType.VolturaAmmSiProvisioning,
            FlowType.VolturaAmmNoProvisioning,
            includeIncSoc ? FlowType.VolturaAmmIncorporazioneSocietaria : undefined,
        ]),
    );

/**
 * @deprecated
 */
export const isVolturaAmmSiNoProvisioning = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(FlowType.VolturaAmmSiProvisioning, FlowType.VolturaAmmNoProvisioning);

/**
 * @deprecated use flowTypeUtil(flowType).equalTo(FlowType.CPRetroattivoExNovo)
 */
export const isFlowTypeExNovo = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(FlowType.CPRetroattivoExNovo);

/**
 * @deprecated
 */
export const isSalesProcessActivation = (salesProcess: AptSalesProcess): boolean =>
    ACTIVATION_SALES_PROCESSES.includes(salesProcess);

/**
 * @deprecated use flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Domiciliazione)
 */
export const isDomiciliationStandAlone = (flowType: FlowType): boolean => (flowType || '').startsWith('DOM_');

/**
 * @deprecated
 */
export const isAdministrativeSwitchIn = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        FlowType.SwitchInAmmVip,
        FlowType.SwitchInAmmRecupero2A,
        FlowType.SwitchInAmmRecupero2B,
        FlowType.SwitchInAmmRecupero2C,
        FlowType.SwitchInAmmDelibera153,
        FlowType.VolturaCambioFornitoreAmmRecupero2A,
        FlowType.VolturaCambioFornitoreAmmRecupero2B,
        FlowType.VolturaCambioFornitoreAmmRecupero2C,
        FlowType.VolturaCambioFornitoreVip,
    );
/**
 * @deprecated
 */
export const isAdministrativeSwitchInWithCeasedAsset = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        FlowType.SwitchInAmmRecupero2A,
        FlowType.SwitchInAmmDelibera153,
        FlowType.VolturaCambioFornitoreAmmRecupero2A,
    );

/**
 * @deprecated
 */
export const isAdministrativeSwitchInWithoutAsset = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        FlowType.SwitchInAmmVip,
        FlowType.SwitchInAmmRecupero2B,
        FlowType.SwitchInAmmRecupero2C,
        FlowType.VolturaCambioFornitoreAmmRecupero2B,
        FlowType.VolturaCambioFornitoreAmmRecupero2C,
        FlowType.VolturaCambioFornitoreVip,
    );
/**
 * @deprecated
 */
export const isAdministrativeRecupero2C = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(FlowType.SwitchInAmmRecupero2C, FlowType.VolturaCambioFornitoreAmmRecupero2C);

export const isNoPlico = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(FlowType.Cessazione, FlowType.DomiciliazioneModifica, FlowType.DomiciliazioneRevoca);

/**
 * @deprecated
 */
export const isVariazioneCommerciale = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(FlowType.VariazioneCommerciale);

/**
 * @deprecated
 */
export const isVariazioneTecnica = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        /* DROP 1 */
        FlowType.VariazioneTecnicaVerifiche,
        FlowType.VariazioneTecnicaVerificheAmministrativa,
        FlowType.VariazioneTecnicaLavori,
        FlowType.VariazioneTecnicaLavoriAmministrativa,
        FlowType.VariazioneTecnicaLavoriDiminuzionePotenza,
        FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa,
        /* DROP 2 */
        FlowType.VariazioneTecnicaLavoriPreventivo,
        FlowType.VariazioneTecnicaLavoriPreventivoAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
        FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasi,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensione,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa,
        /* VT 2.1 */
        FlowType.VariazioneTecnicaLavoriPreventivoModificaImpianto,
        FlowType.VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa,
    );

/**
 * @deprecated
 */
export const isVariazioneTecnicaWithVoltageNeeded = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        /* DROP 1 */
        FlowType.VariazioneTecnicaLavoriDiminuzionePotenza,
        FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa,
        /* DROP 2 */
        FlowType.VariazioneTecnicaLavoriPreventivo,
        FlowType.VariazioneTecnicaLavoriPreventivoAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
        FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasi,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensione,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa,
    );

/**
 * @deprecated
 */
export const isAdministrativeActivation = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        FlowType.AttivazioneAmmRecupero,
        FlowType.AttivazioneAmmRecuperoA01,
        FlowType.AttivazioneAmmRecuperoS01,
    );

export const isActivationComp = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        FlowType.AttivazionePN1,
        FlowType.AttivazioneA40,
        FlowType.AttivazionePwrA01,
        FlowType.AttivazioneN02,
        FlowType.AttivazioneVipPN1,
        FlowType.AttivazioneVipA40,
        FlowType.AttivazionePwrVipA01,
        FlowType.AttivazioneVipN02,
        FlowType.AttivazioneAmmPN1,
        FlowType.AttivazioneAmmA40,
        FlowType.AttivazionePwrAmmA01,
        FlowType.AttivazioneAmmN02,
    );

/**
 * @deprecated
 * @description Controlla se il flowType di attivazione è di tipo amministrativo
 * @param flowType
 * @returns True se il flowType rientra tra quelli di Attivazione Amministrativa altrimenti false
 */
export const isAdminActivationGasOrPwr = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        FlowType.AttivazioneAmministrativa,
        FlowType.AttivazioneAmmGasA01,
        FlowType.AttivazioneAmmPwrS01,
    );

/**
 *
 * @description Controlla se il salesProcess di attivazione è di tipo amministrativo
 * @param salesProcess
 * @returns True se il salesProcess rientra tra quelli di Attivazione Amministrativa altrimenti false
 * @deprecated
 */
export const isFlowTypeAdminActivation = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(FlowType.AttivazioneAmmGasA01, FlowType.AttivazioneAmmPwrS01);

/**
 * Controlla e indica se il flowtype passato in ingresso rientra tra quelli che non necessitano di un carrello SalesForce
 * @param flowType
 * @returns True => Il FlowType rientra tra quelli che non hanno necessità di avere un cart SalesForce altrimenti False
 */
export const flowTypeHasNoCart = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        FlowType.Sospensione,
        FlowType.SospensioneAmministrativa,
        FlowType.Interruzione,
        FlowType.InterruzioneAmministrativa,
        FlowType.Riapertura,
        FlowType.RiaperturaAmministrativa,
        FlowType.RevocaSospensione,
        FlowType.RevocaInterruzione,
        FlowType.RevocaRiapertura,
        FlowType.CA1,
        FlowType.CA1AMM,
        FlowType.CA2,
        FlowType.CA2AMM,
        FlowType.CA3,
        FlowType.CA3AMM,
        FlowType.CA4,
        FlowType.CA4AMM,
        FlowType.CA5,
        FlowType.CA5AMM,
        FlowType.CA7,
        FlowType.CA7AMM,
        FlowType.CAE1,
        FlowType.CAE1AMM,
        FlowType.CAE3,
        FlowType.CAE3AMM,
        FlowType.CAE4,
        FlowType.CAE4AMM,
        FlowType.CAE5,
        FlowType.CAE5AMM,
        FlowType.CAE7,
        FlowType.CAE7AMM,
        FlowType.CAE8,
        FlowType.CAE8AMM,
        FlowType.CAE8RIFVOLT,
        FlowType.CAE8RIFVOLTAMM,
        FlowType.Aggrega,
        FlowType.Disaggrega,
    );

export const isEgonRequest = (data: any): data is EgonRequest => {
    return (
        !!data &&
        typeof data === 'object' &&
        ['number', 'city', 'province_code', 'zipcode'].some((prop) => prop in data)
    );
};

const INDIRIZZO_BASICS_PROPS = ['toponomastica', 'via', 'civico', 'comune', 'prov'];
export const isIndirizzo = (data: any): data is Indirizzo => {
    return !!data && typeof data === 'object' && INDIRIZZO_BASICS_PROPS.some((prop) => prop in data);
};
export const isValidIndirizzo = (data: any): boolean => {
    return isIndirizzo(data) && !!INDIRIZZO_BASICS_PROPS.find((prop) => !!data[prop]);
};

export const isAddress = (data: any): data is Address => {
    return (
        !!data &&
        typeof data === 'object' &&
        [
            'toponym',
            'civic',
            'civicSuffix',
            'municipality',
            'shortProvince',
            'iso3166Alpha3',
            'istatCodeProv',
            'istatCodeMunicipality',
            'civicEgonCode',
            'streetEgonCode',
        ].some((prop) => prop in data)
    );
};

export const isValidAddress = (data: any): boolean => {
    return (
        isAddress(data) && (!['street', 'municipality', 'toponym'].find((prop) => !data[prop]) || !!data.streetEgonCode)
    );
};

export const isValidAddressForQuote = (address: any): boolean => {
    return !!(
        isAddress(address) &&
        address?.street &&
        (address?.civic || address?.civicSuffix) &&
        address?.cap &&
        address?.municipality &&
        address?.shortProvince
    );
};

export const isSalesProcessAllowedDeferredSale = (salesProcess: AptSalesProcess): boolean =>
    [AptSalesProcess.SwitchIn, AptSalesProcess.CambioProdotto].includes(salesProcess);

export const isIbanItalian = (iban: string): boolean => /^\s*IT/i.test(iban);

export const isScontoStandalone = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(FlowType.ScontoStandalone);

export const isAdminChangeProduct = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        FlowType.CPDelibera,
        FlowType.CPAmministrativo,
        FlowType.CPRetroattivoExNovo,
        FlowType.CPRetroattivoAssetCessato,
    );

export const isProcessAttivazioneComplessa = (salesProcess: AptSalesProcess): boolean =>
    [
        AptSalesProcess.AttivazioneA01,
        AptSalesProcess.AttivazioneN02,
        AptSalesProcess.AttivazioneA40,
        AptSalesProcess.AttivazionePN1,
    ].includes(salesProcess);

export const isPrevPN1AllaccioN02 = (salesProcess: AptSalesProcess): boolean =>
    [AptSalesProcess.AttivazioneN02, AptSalesProcess.AttivazionePN1].includes(salesProcess);

export const isCommercialProduct = (productType: AptProductType): boolean =>
    [
        AptProductType.ProdottoCommodityGas,
        AptProductType.TariffaCommodityGas,
        AptProductType.TariffaCommodityLuce,
        AptProductType.ProdottoCommodityLuce,
        AptProductType.AssicurazioneAncillare,
        AptProductType.AssicurazioneIntermediata,
        AptProductType.Manutenzione,
        AptProductType.SmartHome,
        AptProductType.Complex_caldaia,
        AptProductType.Complex_comp_tecnica,
        AptProductType.Complex_condizionatori,
        AptProductType.Complex_pompe_calore,
        AptProductType.Complex_scaldacqua,

        AptProductType.Complex_sist_ric_elettrica,
        AptProductType.Sopralluogo,
    ].includes(productType);

export const isFlowTypeAdminForPriceList = (flowType: FlowType): boolean =>
    flowTypeUtil(flowType).equalTo(
        FlowType.CPRetroattivoExNovo,
        FlowType.CPRetroattivoAssetCessato,
        FlowType.AttivazioneAmministrativa,
        FlowType.AttivazioneAmmRecupero,
        FlowType.AttivazioneN02,
        FlowType.AttivazioneA40,
        FlowType.AttivazionePwrA01,
        FlowType.AttivazioneAmmPwrS01,
        FlowType.AttivazioneAmmRecuperoS01,
        FlowType.AttivazioneAmmRecuperoA01,
        FlowType.AttivazioneAmmGasA01,
        FlowType.AttivazionePN1,
        FlowType.SwitchInAmmRecupero2A,
        FlowType.SwitchInAmmRecupero2B,
        FlowType.SwitchInAmmRecupero2C,
        FlowType.VolturaAmmSiProvisioning,
        FlowType.VolturaAmmNoProvisioning,
    );

export const isAtomicProduct = (productType: AptProductType) =>
    [AptProductType.ProdottoAtomicoCommodityGas, AptProductType.ProdottoAtomicoCommodityLuce].includes(productType);

/**
 * @param address1
 * @param address2
 * @param defaultOnEmptyAddress default value address1 or address2 are empty
 * @returns
 */
export function isSameAddress(
    address1: Address | false,
    address2: Address | false,
    defaultOnEmptyAddress: boolean | null = false,
): boolean {
    const isAddress1Empty = typeof address1 === 'boolean' || !address1?.street;
    const isAddress2Empty = typeof address2 === 'boolean' || !address2?.street;
    if (isAddress1Empty !== isAddress2Empty) {
        return defaultOnEmptyAddress;
    } else if (isAddress1Empty && isAddress2Empty) {
        return true;
    }
    return (
        isAddress(address1) &&
        isAddress(address2) &&
        ((!!address1?.civicEgonCode && address1?.civicEgonCode === address2?.civicEgonCode) ||
            isSameString(getFullAddress(address1, true), getFullAddress(address2, true)))
    );
}

export function isAddressEmpty(address: Address | false): boolean {
    return address === false || !address?.street;
}
/**
 * Insensitive strings comparer
 * @param text1
 * @param text2
 * @returns return true if text1 == text1
 */
export function isSameString(text1: string, text2: string): boolean {
    return (text1 || '').trim().toUpperCase() === (text2 || '').trim().toUpperCase();
}

export function isCommodityFamily(
    family: AptProductFamily,
): family is AptProductFamily.Commodity | AptProductFamily.CommodityLegacy {
    return [AptProductFamily.Commodity, AptProductFamily.CommodityLegacy].includes(family);
}

export const isOrderEntryStateV2 = (value: OrderEntryState | OrderEntryState_v2): value is OrderEntryState_v2 =>
    typeof value === 'object' && value?.version === 2;

export const isOrderEntryStateV1 = (value: OrderEntryState | OrderEntryState_v2): value is OrderEntryState =>
    typeof value === 'object' && value?.version !== 2;

export function isHealthInsurance(product: Partial<Product>): boolean;
export function isHealthInsurance(product: Product): boolean {
    return /salute/i.test(product?.name) && product?.productType === AptProductType.AssicurazioneIntermediata;
}

export function isImplantInsurance(product: Partial<Product>): boolean {
    return /impianti/i.test(product?.name) && product?.productType === AptProductType.AssicurazioneAncillare;
}
export function isApplianceInsurance(product: Partial<Product>): boolean {
    return /elettrodomestici/i.test(product?.name) && product?.productType === AptProductType.AssicurazioneIntermediata;
}

export function isApplianceInsuranceWithoutProductType(productName: string): boolean {
    return /elettrodomestici/i.test(productName);
}

export function isHomeInsurance(product: Partial<Product>): boolean {
    return /casa/i.test(product?.name) && product?.productType === AptProductType.AssicurazioneIntermediata;
}

export function isTechProduct(productType: AptProductType): boolean {
    return [AptProductType.FornituraGas, AptProductType.FornituraLuce].includes(productType);
}

export function hasOnlyExtraCommodities(products: Array<Pick<Product, 'productType'>>): boolean {
    const allProdsTypes = (products || []).map((prod) => prod.productType);
    const containsCommodity = containsProductPower(allProdsTypes) || containsProductGas(allProdsTypes);
    return !containsCommodity && containsExtraCommodities(products);
}

export function containsExtraCommodities(
    products: Product[] | EglProductExtended[] | DeepPartial<Product>[] | AptProductType[],
): boolean {
    const productsTypes =
        typeof products[0] !== 'string'
            ? (products || []).map((prod) => prod.ProductType || prod.productType)
            : products;
    return (
        containsProductInsurance(productsTypes) ||
        containsProductMaintenance(productsTypes) ||
        containsProductSmartHome(productsTypes) ||
        containsProductComplex(productsTypes)
    );
}

export function isProductAppurtenance(product: Product) {
    return (
        isCommodityFamily(product?.family) &&
        product?.powerOrGas === AptCommodityType.Power &&
        product?.configurations?.destinationUse === DestinationUse.Appurtenances
    );
}

export function isNullOrUndefined(value: unknown): boolean {
    return [null, undefined].includes(value);
}
export function isProductComplex(productType: AptProductType) {
    const complexProductTypes: AptProductType[] = [
        AptProductType.Complex_caldaia,
        AptProductType.Complex_scaldacqua,
        AptProductType.Complex_condizionatori,
        AptProductType.Complex_sist_ric_elettrica,
        AptProductType.Complex_pompe_calore,
        AptProductType.Complex_comp_tecnica,
        AptProductType.Sopralluogo,
    ];
    return containsProductTypes(productType, complexProductTypes);
}

export function isChannelRemote(channel: D365ChannelCode): boolean {
    return REMOTE_CHANNELS.includes(channel);
}

export const allowedRestoreSalesProcess = (salesProcess: AptSalesProcess): boolean =>
    [
        AptSalesProcess.SwitchIn,
        AptSalesProcess.AttivazioneSemplice,
        AptSalesProcess.VolturaConSwitchIn,
        AptSalesProcess.ExtraCommoditySale,
    ].includes(salesProcess);

export const isAdministrativeOrVip = (flowType: FlowType): boolean =>
    MACRO_FLOW_TYPES[MacroFlowType.Administrative].indexOf(flowType) >= 0 ||
    MACRO_FLOW_TYPES[MacroFlowType.Vip].indexOf(flowType) >= 0;

export const areBODateFiltersSet = (dateFilterBo: BoFilterByDate): boolean => {
    if (!dateFilterBo) return false;
    return !!dateFilterBo.dateEnd && !!dateFilterBo.dateStart;
};
