import { Injectable } from '@angular/core';
import { CartService, User, UserService } from '@congacommerce/ecommerce';
import { Store } from '@ngrx/store';
import { isBoolean, isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { FlowType, MacroFlowType } from '../../../../store/models/flow-type';
import { FullState } from '../../../../store/models/full-state';
import { GasAdministrativeTechnicalData, WinBackType } from '../../../../store/models/order-entry-state';
import { Address, OrderEntryState_v2, Product, TechnicalDetails } from '../../../../store/models/order-entry-state_v2';
import { UserState } from '../../../../store/models/user-state';
import { EglState } from '../../../../store/reducers';
import { selectFullState } from '../../../../store/selectors/common.selectors';
import {
    filterCommodityProducts,
    filterMaintenanceProducts,
    productFieldSelectorGenerator,
} from '../../../../store/selectors/selector-utility.functions';
import { AptAddressType } from '../../../enums/apttus/apt-address-type';
import { APT_BILL_TYPE_TO_DELIVERY_CHANNEL_QUOTE_MAP } from '../../../enums/apttus/apt-bill-type';
import { AptDeliveryChannelQuote } from '../../../enums/apttus/apt-delivery-channel';
import { AptContactRole } from '../../../enums/apttus/apt-mb-representative';
import { AptPaymentInstrument } from '../../../enums/apttus/apt-payment-instrument';
import { D365ChannelCode } from '../../../enums/d365/d365-channel-code';
import { D365CustomerSegment } from '../../../enums/d365/d365-customer-segment';
import { D365SignatureType } from '../../../enums/d365/d365-signature-type';
import { PrivacyType } from '../../../enums/shared/privacy-question-type';
import { SubProductType } from '../../../enums/shared/sub-product-type';
import { getFullAddressString } from '../../../functions/address.functions';
import { isVulnerableOver75, reverseCf } from '../../../functions/cf-piva.functions';
import {
    cleanObj,
    isActivePackageProduct,
    isVisibleNotTechProduct,
    newGuid,
    parseToDate,
    showResidentialAddress,
} from '../../../functions/misc.functions';
import {
    aptCommodityTypesToWinBackType,
    castStringIntoBoolean,
    getAgentD365toApt,
    mastershipTypeToD365AccountMigrated,
    salesProcessOrOperationTypeToFlowType,
} from '../../../functions/remap.functions';
import { formatPrice, getCivicZeroToSNC, getCompleteCivic } from '../../../functions/string-format.functions';
import {
    containsCommodity,
    flowTypeUtil,
    isAddress,
    isValidAddressForQuote,
} from '../../../functions/verifications.functions';
import { SiNoType } from '../../../models/app/si-no-type';
import {
    Account,
    AttachmentInfo,
    BillingAccount,
    CartToQuoteAppointment,
    CartToQuoteRequest,
    ChannelAndAgency,
    CreditCheck,
    CTQAddress,
    CTQContact,
    ElectronicInvoicing,
    ExecutionType,
    HolderPaymentTool,
    IdentityDocumentInfo,
    InvalidCf,
    LeadAndCampaign,
    PaymentTool,
    Privacy,
    Products,
    Signature,
    TaxVatScipafi,
} from '../../../models/apttus/request-response/cart-to-quote-request';
import { EglCartExtended } from '../../../models/apttus/tables/cart/egl-cart-extended';
import { VirtualAgent } from '../../../models/user/agent';
import { Gender } from '../../../models/user/contact';
import { LoggerService } from '../../shared/logger.service';
import { UtilityService } from '../../shared/utility.service';
import { mapPriceStatus } from '../utility/egl-cart.service';
import { AttributeFactory } from './attribute-factory';

@Injectable({
    providedIn: 'root',
})
export class CartToQuoteRequestService {
    constructor(
        private store: Store<EglState>,
        private utilitySrv: UtilityService,
        private userSrv: UserService,
        private logger: LoggerService,
        private cartSrv: CartService,
    ) {}

    /**
     * @description: returns the request for new cart to quote
     */
    getCartToQuoteReq(): Observable<Partial<CartToQuoteRequest>> {
        /**
         * IL PRIMO CHE AGGIUNGE SELETTORI A QUESTA COMBINELATEST SENZA UNA REALE NECESSITA'
         * LO VENGO A CERCARE DI NOTTE! LA REQUEST VIENE COSTRUITA SULLA BASE DELLO STATE.
         *
         * *******************************************************************************************
         * ***************** ☠ NON METTERE IN QUESTO MAPPER LOGICHE DI BUSINESS! ☠ *****************
         * *******************************************************************************************
         *
         * es1: in caso di una certa modalità operativa lo stato della quote deve essere sempre 'COMPLETATO'.
         *      NON è qui che devi mettere l'IF.
         **/

        return combineLatest([
            this.userSrv.me(),
            this.store.select(selectFullState),
            this.cartSrv.getMyCart().pipe(map((cart: EglCartExtended) => cart)),
        ]).pipe(
            take(1),
            map(([owner, fullState, cart]) => {
                const { user, salesProcess, isDelayInsertion, orderEntryV2 } = fullState;
                const winBackType = aptCommodityTypesToWinBackType(
                    orderEntryV2?.products.map(({ powerOrGas, isWinBack }) => (isWinBack ? powerOrGas : null)),
                );

                const fromCartRequest = cleanObj({
                    cartId: CartService.getCurrentCartId(),
                    salesProcess: salesProcess,
                    quoteStatus: this.getQuoteStatus(orderEntryV2).quoteStatus,
                    quoteSubStatus: this.getQuoteStatus(orderEntryV2).quoteSubstatus,
                    account: this.getAccount(orderEntryV2, user),
                    contact: this.getContact(orderEntryV2, user),
                    identityDocument: this.getIdentityDocumentInfo(orderEntryV2),
                    creditCheckDetails: this.getCreditCheck(orderEntryV2),
                    privacy: this.getPrivacy(orderEntryV2, user),
                    isWinBack: orderEntryV2?.products.some((product) => product?.isWinBack) ? true : null,
                    winBackType: winBackType !== WinBackType.None ? winBackType : null,
                    contractCode: this.getContractCode(orderEntryV2),
                    billingAccount: this.getBillingAccount(orderEntryV2, user),
                    electronicInvoicing: this.getElectronicInvoicing(orderEntryV2),
                    products: this.getProducts(fullState),
                    realEstateOwnership: orderEntryV2?.products?.find(
                        (product) => product?.configurations?.propertyOwnership,
                    )?.configurations?.propertyOwnership,
                    channelAndAgency: this.getChannelAndAgency(orderEntryV2, user, owner),
                    invalidCf: this.getInvalidCf(orderEntryV2),
                    leadAndCampaign: this.getLeadAndCampaign(user),
                    signature: this.getSignature(orderEntryV2, isDelayInsertion),
                    executionType: this.getExecutionType(orderEntryV2),
                    attachments: this.getAttachments(orderEntryV2),
                    isMortisCausa: orderEntryV2?.products.some(({ isMortisCausa }) => isMortisCausa) ? true : null,
                    partNumber: orderEntryV2?.products?.find((product) => product?.partNumber)?.partNumber,
                    appointment: this.getAppointment(orderEntryV2),
                    combinedSaleQuoteId: orderEntryV2?.linkedCommodity?.quoteId,
                    incidentId: orderEntryV2?.incident?.ticketNumber,
                    skipCosts: orderEntryV2?.products.some((product) => product?.prices?.skip),
                    agreementCode: cart?.egl_agreement_code,
                    sendCommunications: orderEntryV2?.sendCommunications,
                    sourceCustomerMastershipCode: mastershipTypeToD365AccountMigrated(
                        orderEntryV2?.products?.find((product) => product?.sourceCustomer?.mastership)?.sourceCustomer
                            ?.mastership,
                    ),
                    dataOwnershipChangeCode: user?.dataOwnershipChange?.data?.code,
                    blockDataEditingOnDoiCheckDoiResend: orderEntryV2?.blockDataEditingOnDoiCheckDoiResend,
                    transferModel: orderEntryV2?.transferModel,
                }) as Partial<CartToQuoteRequest>;

                this.logger.info('[PRICE] Price status before fromCart', mapPriceStatus(cart));
                this.logger.info('fromCart request generated:', fromCartRequest);
                return fromCartRequest;
            }),
            catchError((err) => {
                this.logger.error('cart-to-quote-request', 'errore creazione request fromCart', err);
                throw new Error('errore creazione request fromCart');
            }),
        );
    }

    private _getProductBillingData(currentProduct: Product, user: UserState): BillingAccount {
        return {
            type: currentProduct?.paymentInfo?.paymentType,
            billingAddress: this.getAddress(currentProduct?.communicationAddress),
            paymentTool: this.getPaymentTool(currentProduct, user),
            deliveryChannel: this.getDeliveryChannel(currentProduct),
        };
    }

    private getContractCode(orderEntryV2: OrderEntryState_v2): string {
        return orderEntryV2?.numeroPlico?.code || 'NO_CODE';
    }

    private getQuoteStatus(orderEntryV2: OrderEntryState_v2): { quoteStatus: string; quoteSubstatus: string } {
        /**
         * NON aggiungere logiche qui dentro. Dispatchale nello state prima di chiamare il saveQuote.
         * in modo che
         *      erderEntry.quoteStateModel?.status
         *      orderEntry.quoteStateModel?.subStatus
         * abbia il valore che si vuole mappare nella request
         */
        return {
            quoteStatus: orderEntryV2.quoteStateModel?.status,
            quoteSubstatus: orderEntryV2.quoteStateModel?.subStatus,
        };
    }

    private getSegment(user: UserState): D365CustomerSegment {
        return user?.cartSegment;
    }

    private getHomeAddress(orderEntryV2: OrderEntryState_v2, cartSegment: D365CustomerSegment): CTQAddress {
        const rcDanni = (orderEntryV2?.products || []).find((p) => p?.subProductType === SubProductType.RCDanni);
        if (rcDanni) {
            return this.getAddress(rcDanni.communicationAddress);
        }
        return showResidentialAddress({
            products: orderEntryV2.products,
            cartSegment,
        })
            ? this.getAddress(orderEntryV2.contact?.mainAddress)
            : null;
    }

    private getDomicileAddress(orderEntryV2: OrderEntryState_v2): CTQAddress {
        return orderEntryV2?.contact?.domicileAddress ? this.getAddress(orderEntryV2?.contact?.domicileAddress) : null;
    }

    private getCommunicationAddress(orderEntryV2: OrderEntryState_v2): CTQAddress {
        return this.getAddress(
            orderEntryV2?.products?.find((product) => isAddress(product?.communicationAddress))?.communicationAddress,
        );
    }

    private getCompanyAddress(orderEntryV2: OrderEntryState_v2, user: UserState): CTQAddress {
        return this.isMicroBusiness(user) ? this.getAddress(orderEntryV2?.contact?.mainAddress) : null;
    }

    private isMicroBusiness(user: UserState): boolean {
        return this.getSegment(user) === D365CustomerSegment.Microbusiness;
    }

    private isResidential(user: UserState): boolean {
        return this.getSegment(user) === D365CustomerSegment.Residential;
    }

    private getAccount(orderEntryV2: OrderEntryState_v2, user: UserState): Account {
        return {
            customerCode: user.contact?.egl_customercode,
            segment: this.getSegment(user),
            firstname: user.contact?.firstname,
            lastname: user.contact?.lastname,
            companyName: orderEntryV2.anagraficaMb?.companyName,
            mastershipCode: mastershipTypeToD365AccountMigrated(user.customerMastership),
            taxCode: user.contact?.egl_taxcode || user.customerInfo?.TaxCode,
            vatCode: user.contact?.egl_vatcode || user.customerInfo?.VatCode,
            emailAddress: user.contact?.emailaddress1,
            telephone1prefix: user.contact?.prefixMobilephone || user.contact?.prefixTelephone1,
            telephone1: user.contact?.mobilephone || user.contact?.telephone1,
            telephone2prefix: user.contact?.prefixTelephone2,
            telephone2: user.contact?.telephone2,
            companyAddress: this.getCompanyAddress(orderEntryV2, user),
            homeAddress: this.getHomeAddress(orderEntryV2, this.getSegment(user)),
            domicileAddress: this.getDomicileAddress(orderEntryV2),
            communicationAddress: this.getCommunicationAddress(orderEntryV2),
            companyLegalForm: orderEntryV2.anagraficaMb?.legalForm,
            companyNumberOfEmployees: user.customerInfo?.NumberOfEmployees,
            companyRevenue: user.customerInfo?.Revenue,
            favoriteCommunicationChannel: user.contact?.preferredContact,
            ...this.getVulnerableFields(user, orderEntryV2.products, orderEntryV2.flowType),
            ...this.getAtecoSectorActivity(orderEntryV2.products),
        };
    }

    private getVulnerableFields(user: UserState, products: Product[], flowType: FlowType) {
        if (flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.CambioProdotto) && !user.contact?.egl_vatcode) {
            const replacementProducts = products.filter((p) => p.lineItemStatus !== 'Cancelled');
            return {
                over75: isVulnerableOver75(user.contact?.egl_taxcode, user?.contact?.ageRange),
                socialbonus:
                    castStringIntoBoolean(user.customerInfo?.vulnerabilitytypeSocialBonus) ||
                    replacementProducts.some((product) => product.configurations.vulnerabilitySocialBonus),
                saemapre:
                    castStringIntoBoolean(user.customerInfo?.vulnerabilitytypeSaemapre) ||
                    replacementProducts.some((product) => product.configurations.vulnerabilitySaeMapre),
                disability:
                    castStringIntoBoolean(user.customerInfo?.vulnerabilitytypeDisabled) ||
                    replacementProducts.some((product) => product.configurations.vulnerabilityDisabled),
            };
        }

        return {
            over75: !!user.contact?.egl_vulnerabilitytype_over75,
            socialbonus: !!user.contact?.egl_vulnerabilitytype_socialbonus,
            saemapre: !!user.contact?.egl_vulnerabilitytype_saemapre,
            disability: !!user.contact?.egl_vulnerabilitytype_disabled,
        };
    }

    private getAtecoSectorActivity(products: Product[]) {
        // da rimuovere dopo rimozione lato apim. SPOSTATO A LIVELLO DI PRODOTTO
        return {
            companyAtecoCode: (products || []).find((prod) => prod?.businessDetails?.ateco)?.businessDetails?.ateco,
            companyAtecoSector: (products || []).find((prod) => prod?.businessDetails?.sector)?.businessDetails?.sector,
            companyAtecoActivity: (products || []).find((prod) => prod?.businessDetails?.activity)?.businessDetails
                ?.activity,
        };
    }

    private getAddress(address: Address | false): CTQAddress {
        address = getCivicZeroToSNC(address);
        if (address && isValidAddressForQuote(address)) {
            const {
                toponym,
                street,
                municipality,
                region,
                shortProvince,
                province,
                cap,
                country,
                iso3166Alpha3,
                istatCodeMunicipality,
                istatCodeProv,
                streetEgonCode,
                civicEgonCode,
                isDwellingTypePropertyHome,
                isDwellingTypeUsualHome,
            } = address || ({} as Address);
            return {
                // Bug #173210: 'se egon mi manda un indirizzo privo di toponimo, la sales up deve settare come valore di default "VIA"'
                toponym: address?.toponym || 'VIA',
                street: address?.street,
                civic: getCompleteCivic(address),
                municipality: address?.municipality,
                region: address?.region,
                shortProvince: address?.shortProvince,
                province: address?.province,
                cap: address?.cap,
                country: address?.country || address?.iso3166Alpha3 || 'Italia',
                istatCodeProv: address?.istatCodeProv,
                istatCodeMunicipality: address?.istatCodeMunicipality,
                civicEgonCode: address?.civicEgonCode,
                streetEgonCode: address?.streetEgonCode,
                certified: !!address?.streetEgonCode || !!address?.civicEgonCode,
                fullAddress: getFullAddressString(address),
                isDwellingTypePropertyHome: address?.isDwellingTypePropertyHome,
                isDwellingTypeUsualHome: address?.isDwellingTypeUsualHome,
            };
        }
        return null;
    }

    private getContact(orderEntryV2: OrderEntryState_v2, user: UserState): CTQContact {
        let reverseContactData = {};
        const contactCf = this.isMicroBusiness(user) ? orderEntryV2.anagraficaMb?.cfLegal : user.contact?.egl_taxcode;
        if (contactCf) {
            const { gender, birthplace, birthplaceProvincia, birthday } = reverseCf(contactCf) || {};
            reverseContactData = cleanObj({
                gender,
                birthDate: birthday,
                birthCountry: birthplaceProvincia === 'EE' ? birthplace : 'ITALIA',
                birthPlace: birthplace,
                birthProvince: birthplaceProvincia,
            });
        }

        if (this.isMicroBusiness(user)) {
            return {
                type: orderEntryV2.anagraficaMb?.representativeType,
                firstname: orderEntryV2.anagraficaMb?.nameLegal,
                lastname: orderEntryV2.anagraficaMb?.surnameLegal,
                taxcode: orderEntryV2.anagraficaMb?.cfLegal,
                ...reverseContactData,
            };
        } else {
            return {
                type: AptContactRole.Cliente,
                firstname: user.contact?.firstname,
                lastname: user.contact?.lastname,
                taxcode: user.contact?.egl_taxcode || user.customerInfo?.TaxCode,
                gender: <Gender>user.contact?.gender,
                birthCountry: user.contact?.birthCountry,
                birthDate: user.contact?.birtDate ? moment(user.contact.birtDate).format('YYYY-MM-DD') : null,
                birthPlace: user.contact?.birthPlace,
                birthProvince: user.contact?.birthProvince,
                isCustomer: user.contact?.isCustomer,
                ...reverseContactData,
            };
        }
    }

    private getIdentityDocumentInfo(orderEntryV2: OrderEntryState_v2): IdentityDocumentInfo {
        return {
            documentType: orderEntryV2.fotoDocumenti?.tipoDocumento,
            documentNumber: orderEntryV2.fotoDocumenti?.numeroDocumento,
            issuedBy: orderEntryV2.fotoDocumenti?.emessoDa,
            issueDate: parseToDate(orderEntryV2.fotoDocumenti?.rilasciatoIl),
        };
    }

    private skipCC(flowType: FlowType, isFakeResponse: boolean): boolean {
        // verificare se ci si può basare solo su isFakeResponse
        return (
            isFakeResponse ||
            flowTypeUtil(flowType).equalTo(FlowType.VariazioneCommerciale) ||
            flowTypeUtil(flowType).inMacroFlowTypes(
                MacroFlowType.Domiciliazione,
                MacroFlowType.CambioProdotto,
                [MacroFlowType.SwitchIn, MacroFlowType.Administrative],
                [MacroFlowType.VolturaSwitchIn, MacroFlowType.Administrative],
                [MacroFlowType.Attivazione, MacroFlowType.Administrative, MacroFlowType.Vip],
            )
        );
    }

    private getCreditCheck({ creditCheckStatus, flowType }: OrderEntryState_v2): CreditCheck {
        const skipCC = this.skipCC(flowType, !!creditCheckStatus?.isFakeResponse);
        if (!skipCC && creditCheckStatus) {
            const TAX_VAT_REMAP = {
                'NON DISPONIBILE': TaxVatScipafi.Retry,
            };
            return {
                blacklist: creditCheckStatus?.ccDetails?.blacklist,
                blacklist2: creditCheckStatus?.ccDetails?.blacklist2,
                newBlacklist: creditCheckStatus?.ccDetails?.newBlacklist,
                whitelist: creditCheckStatus?.ccDetails?.whitelist,
                unsolvedNds: creditCheckStatus?.ccDetails?.unsolvedNds,
                avgInvoiceAmount: creditCheckStatus?.ccDetails?.avgInvoiceAmount,
                residualNds: creditCheckStatus?.ccDetails?.residualNds,
                unsolvedTaxCodeNds: creditCheckStatus?.ccDetails?.unsolvedTaxCodeNds,
                unsolvedTaxCodeNdsResult: creditCheckStatus?.ccDetails?.unsolvedTaxCodeNdsResult,
                paymentScore: creditCheckStatus?.ccDetails?.paymentScore,
                ceaseReasonCode: creditCheckStatus?.ccDetails?.ceaseReasonCode,
                unsolvedIbanNds: creditCheckStatus?.ccDetails?.unsolvedIbanNds,
                cribis: creditCheckStatus?.ccDetails?.cribis,
                checkDate: parseToDate(creditCheckStatus?.checkDate),
                taxVatScipafi:
                    TAX_VAT_REMAP[creditCheckStatus?.taxVatDetails?.scipafi] ||
                    creditCheckStatus?.taxVatDetails?.scipafi,
                taxVatSdi: creditCheckStatus?.taxVatDetails?.sdi,
                canProceed: creditCheckStatus?.canProceed,
                checkBalanceExceeded: creditCheckStatus?.ccDetails?.checkBalanceExceeded,
            };
        }
    }

    private getPrivacy(orderEntryV2: OrderEntryState_v2, user: UserState): Privacy {
        const privacyData = orderEntryV2.contact?.privacyTrattDatiPers || {};
        const privacyD365 = user?.customerInfo;
        return {
            privacy1: this.getCalculatedPrivacy(privacyData[PrivacyType.InizPromozionaliEgl], privacyD365?.Privacy1),
            privacy2: this.getCalculatedPrivacy(privacyData[PrivacyType.AnalisiMercato], privacyD365?.Privacy2),
            privacy3: this.getCalculatedPrivacy(privacyData[PrivacyType.InizPromoTerzeP], privacyD365?.Privacy3),
        };
    }

    private getBillingAccount(orderEntryV2: OrderEntryState_v2, user: UserState): BillingAccount {
        // Bug 238909 - Prendo il primo asset attivo, non tecnico e con lineItemStatus non in Cancelled
        // (questo perché per CP ho necessità di prendere il lineItemStatus Upgraded)
        // Mantengo il fallback di prendere il primo asset attivo e non tecnico per i processi con soli lineItemStatus in Cancelled (Es: Cessazione)
        const productCC =
            orderEntryV2.products?.find(
                (c) =>
                    !isNil(c.paymentInfo) &&
                    isActivePackageProduct(c) &&
                    (c.paymentInfo?.paymentInstrument === AptPaymentInstrument.AddebitoCC ||
                        c.paymentInfo?.paymentInstrument === AptPaymentInstrument.CartaCredito),
            ) ||
            orderEntryV2.products?.find(
                (c) =>
                    !isNil(c.paymentInfo) &&
                    isVisibleNotTechProduct(c) &&
                    (c.paymentInfo?.paymentInstrument === AptPaymentInstrument.AddebitoCC ||
                        c.paymentInfo?.paymentInstrument === AptPaymentInstrument.CartaCredito),
            );

        const productCommodity =
            orderEntryV2.products?.find(
                (c) => containsCommodity(c.productType) && !isNil(c.paymentInfo) && isActivePackageProduct(c),
            ) ||
            orderEntryV2.products?.find(
                (c) => containsCommodity(c.productType) && !isNil(c.paymentInfo) && isVisibleNotTechProduct(c),
            );

        const product =
            productCC ||
            productCommodity ||
            orderEntryV2.products?.find((c) => !isNil(c.paymentInfo) && isActivePackageProduct(c)) ||
            orderEntryV2.products?.find((c) => !isNil(c.paymentInfo) && isVisibleNotTechProduct(c));

        return {
            type: product?.paymentInfo?.paymentType,
            paymentTool: this.getPaymentTool(product, user), // @todo - da approfondire per cambio prodotto e cessazione
            billingAddress: this.getCommunicationAddress(orderEntryV2),
            deliveryChannel: this.getDeliveryChannel(product),
            provider: orderEntryV2?.provider,
        };
    }

    private getDeliveryChannel(product: Product): AptDeliveryChannelQuote {
        return APT_BILL_TYPE_TO_DELIVERY_CHANNEL_QUOTE_MAP[product?.configurations?.invoiceShippingMethod];
    }

    private getPaymentTool(product: Product, user: UserState): PaymentTool {
        const paymentTool = {
            id: product?.paymentInfo?.paymentTool?.id,
            oldId: product?.paymentInfo?.paymentTool?.oldId, // in caso di REVOCA/MODIFICA domiciliazione contiene l'id della carta interessata
            caseID: product?.paymentInfo?.paymentTool?.caseID,
            deactivateOldPaymentTool: product?.paymentInfo?.paymentTool?.deactivateOldPaymentTool,
            instrumentType: product?.paymentInfo?.paymentInstrument,
            billingPreferenceCode: product?.paymentInfo?.paymentTool?.billingPreferenceCode,
            revocationReason: product?.paymentInfo?.paymentTool?.revocationReason,
            membership: product?.paymentInfo?.paymentTool?.membership,
            ...(product?.paymentInfo?.paymentTool?.iban
                ? {
                      iban: product?.paymentInfo?.paymentTool?.iban,
                      holder: this.getHolderPaymentToolOrDefault(product, user),
                      sepaSubscriber: {
                          taxcode: product?.paymentInfo?.paymentTool?.sepaSubscriber?.fiscalCode,
                          firstname: product?.paymentInfo?.paymentTool?.sepaSubscriber?.firstName,
                          lastname: product?.paymentInfo?.paymentTool?.sepaSubscriber?.lastName,
                      },
                  }
                : {}),
        };
        return paymentTool;
    }

    private getHolderPaymentToolOrDefault(product: Product, user: UserState): HolderPaymentTool {
        if (product?.paymentInfo?.paymentInstrument !== AptPaymentInstrument.AddebitoCC) return;

        const definedHolder = cleanObj<HolderPaymentTool>({
            companyName: product?.paymentInfo?.paymentTool?.holder?.companyName,
            companyVatcode: product?.paymentInfo?.paymentTool?.holder?.vatCode,
            taxcode: product?.paymentInfo?.paymentTool?.holder?.fiscalCode,
            firstname: product?.paymentInfo?.paymentTool?.holder?.firstName,
            lastname: product?.paymentInfo?.paymentTool?.holder?.lastName,
        });
        if (Object.keys(definedHolder).length > 0) {
            return definedHolder as HolderPaymentTool;
        }

        // Default Holder from User
        if (this.isResidential(user)) {
            return {
                companyName: null,
                companyVatcode: null,
                taxcode: user.contact?.egl_taxcode,
                firstname: user.contact?.firstname,
                lastname: user.contact?.lastname,
            };
        } else {
            return {
                companyName: user.contact?.name,
                companyVatcode: user.contact?.egl_vatcode,
                taxcode: user.contact?.egl_taxcode,
                firstname: user.contact?.firstname,
                lastname: user.contact?.lastname,
            };
        }
    }

    private getElectronicInvoicing(OrderEntryState_v2: OrderEntryState_v2): ElectronicInvoicing {
        return {
            recipientCode: OrderEntryState_v2.fatturazioneElettronica?.codiceDestinatario,
            pec: OrderEntryState_v2.fatturazioneElettronica?.pec,
        };
    }

    private getProducts(fullState: FullState): Products[] {
        return (fullState.orderEntryV2.products || [])
            .filter((product) => product.productId || product.lineItemId) // necessario per filtrate i prodotti 'fake' per domiciliazione
            .map((product) => ({
                bookingId: product.booking?.bookingId,
                ese: product.subsidiary?.agencyBranch?.egl_agencybranchid || product.ese?.Id,
                bookableResource: product.booking?.bookableResourceId,
                lineItemId: product.lineItemId,
                distributorVatCode:
                    product?.technicalDetails?.gasDistributorTaxCode ||
                    this.getDistributorVatCode(fullState.orderEntryV2),
                productId: product.productId,
                name: product.name,
                pdf: product.pdf,
                supplyCode: product.supplyCode,
                family: product.family,
                attributeValues: AttributeFactory.getAttributes(fullState, product).keyValue(),
                realEstateOwnership: product?.configurations?.propertyOwnership,
                isWinBack: product?.isWinBack,
                sourceCustomerAssetId: product?.sourceCustomer?.assetId,
                appointment: {
                    location: product?.appointment?.location,
                    notes: product?.appointment?.notes,
                    isAvailable: product?.appointment?.available,
                },
                siteInspection: product?.booking?.bookingId ? 'SI' : 'NO',
                prices:
                    (product?.prices && {
                        priceSDR: formatPrice(product?.prices?.sdr),
                        priceOPS: formatPrice(product?.prices?.ops),
                        priceISI: formatPrice(product?.prices?.isi || 0),
                    }) ||
                    null,
                selfReading:
                    product?.technicalDetails?.needed?.meterCounter === product?.technicalDetails?.meterCounter // autolettura letta dal cliente === autolettura confermata da Net@
                        ? product?.technicalDetails?.needed?.meterCounter // autolettura letta dal cliente
                        : null,
                gasTechnicalDetails: this.getGasTechnicalDetails(product?.technicalDetails),
                shippingAddress:
                    product?.addressType === AptAddressType.Spedizione
                        ? this.getAddress(product?.deliveryAddress)
                        : null,
                supplyAddress:
                    product?.addressType === AptAddressType.Fornitura
                        ? this.getAddress(product?.deliveryAddress)
                        : null,
                immediateEffect:
                    flowTypeUtil(salesProcessOrOperationTypeToFlowType(fullState.salesProcess)).inMacroFlowTypes(
                        MacroFlowType.Attivazione,
                    ) && product?.powerOrGas
                        ? product?.activationImmediateEffect
                        : product?.immediateEffect,
                effectiveDate:
                    flowTypeUtil(salesProcessOrOperationTypeToFlowType(fullState.salesProcess)).inMacroFlowTypes(
                        MacroFlowType.Attivazione,
                    ) && product?.powerOrGas
                        ? product.actDate
                        : product?.effectiveDate,
                isCommodityActive: !!product?.isCommodityActive,
                billingAccount: this._getProductBillingData(product, fullState?.user),
            }));
    }

    private getChannelAndAgency(orderEntryV2: OrderEntryState_v2, user: UserState, owner: User): ChannelAndAgency {
        const agent = user.agentInfo?.VirtualAgents?.find((x) => x.IsCurrentSelected) || new VirtualAgent();
        const impersonated = orderEntryV2.impersonatedAgent;

        return {
            userId: owner.Id,
            userProfile: getAgentD365toApt(user.agentInfo?.Type),
            salesAgent: impersonated?.AgentCode || agent.AgentCode,
            userCode: impersonated?.RealAgentCode || user.agentInfo?.Agent.Code,
            coCode: impersonated?.CurrentCode || agent?.CurrentCode,
            employeeLastname: impersonated?.AgentName || user.agentInfo?.Agent?.Name,
            instoreSale: !isEmpty(impersonated)
                ? impersonated?.IsInStore
                : agent.VirtualAgency?.Channel?.CanSetIsInStore
                  ? agent.IsInStore
                      ? SiNoType.Si
                      : SiNoType.No
                  : null,
            agencyCode: agent.VirtualAgency?.Code,
            salesChannel: agent.VirtualAgency?.Channel?.Code,
            salesChannelName: agent.VirtualAgency?.Channel?.Name,
            numberSignRUIagency: agent.VirtualAgency?.RuiEnrollmentNumber,
            dateSignRUIagency: this.utilitySrv.convertDateD365ToApt(agent.VirtualAgency?.RuiEnrollmentDate),
            companyName: user.agentInfo?.Agency.Name,
            addressAgency: agent.VirtualAgency?.RegisteredOfficeAddress,
            systemOrigin:
                agent.VirtualAgency?.Channel.Code === D365ChannelCode.TelesellingInbound ? 'CALL CENTER' : 'AGENZIA',
            salesAgentBlacklist: user.agentInfo?.Blacklisted,
            intermediaryFirstname: orderEntryV2.branchAgenziaAgente?.ResponsibleFirstName,
            seatName: orderEntryV2.branchAgenziaAgente?.Name,
            mailSeat: orderEntryV2.branchAgenziaAgente?.Email,
            pecSeat: orderEntryV2.branchAgenziaAgente?.Pec,
            intermediaryLastname: orderEntryV2.branchAgenziaAgente?.ResponsibleLastName,
            addressSeat: orderEntryV2.branchAgenziaAgente?.AgencyBranchAddress,
            pecAgency: orderEntryV2.branchAgenziaAgente?.Pec,
            phoneSeat: orderEntryV2.branchAgenziaAgente?.Telephone,
            dateSignRUIintermediary: this.utilitySrv.convertDateD365ToApt(
                orderEntryV2.branchAgenziaAgente?.ResponsibleRuiEnrollmentDate,
            ),
            numberSignintermediary: orderEntryV2.branchAgenziaAgente?.ResponsibleRuiEnrollmentNumber,
            operationalSeats: orderEntryV2.agencyBranchForMonitoring || orderEntryV2.branchAgenziaAgente?.Name,
        };
    }

    private getInvalidCf(orderEntryV2: OrderEntryState_v2): InvalidCf {
        return {
            previousFiscalcode: orderEntryV2.invalidCf?.siebelOldCf,
            customerCode: orderEntryV2.invalidCf?.siebelCustomerCode,
        };
    }

    private getLeadAndCampaign(user: UserState): LeadAndCampaign {
        return {
            campaignId: user.lead?.campaignId,
            leadId: user.lead?.campaignId && user.lead?.egl_code ? user.lead?.egl_code : '',
            campaignName: user.lead?.campaignId && user.lead?.campaignName ? user.lead?.campaignName : '',
            campaignCode: user.lead?.campaignId && user.lead?.campaignCode ? user.lead?.campaignCode : '',
            campaignTypeCode: user.lead?.campaignId && user.lead?.campaignTypeCode ? user.lead?.campaignTypeCode : '',
        };
    }

    private getSignature(orderEntryV2: OrderEntryState_v2, isDelayInsertion: boolean): Signature {
        /**
         * NON aggiungere logiche qui dentro. Dispatchale nello state prima di chiamare il saveQuote.
         * in modo che orderEntry.firma
         * abbia il valore che si vuole mappare nella request
         */

        return {
            mode: orderEntryV2.firma?.d365SignatureType, // viene transcodificato lato apim
            checkcallBlocking: orderEntryV2.firma?.checkCallBlocking, // recuperato da D365/getCheckType D365
            confirmMode: orderEntryV2.firma?.confirmationType, // recuperato da D365/getCheckType
            mp3Sent: orderEntryV2.mp3Info?.uploaded,
            mp3Date: orderEntryV2.mp3Info?.uploaded && orderEntryV2.mp3Info?.uploadDate,
            isDelayInsertion: isDelayInsertion,
            date: this.getSignDate(orderEntryV2),
        };
    }

    private getSignDate(orderEntryV2: OrderEntryState_v2): Date {
        let signatureDate: Date = null;
        const effectiveDate = orderEntryV2.products?.find((prod) => prod.effectiveDate)?.effectiveDate || null;
        if (orderEntryV2.firma?.signedDate) {
            signatureDate = parseToDate(orderEntryV2.firma?.signedDate);
        } else if (orderEntryV2.firma?.d365SignatureType === D365SignatureType.NessunaFirma) {
            const today = new Date();
            // #175849 la signDate non deve essere superiore alla effective date
            signatureDate = effectiveDate >= today ? today : effectiveDate;
        }
        if (signatureDate && signatureDate > effectiveDate) {
            this.logger.warn(
                `La signDate [${signatureDate}] non deve essere superiore alla effective date [${effectiveDate}]`,
            );
        }
        return signatureDate;
    }

    /**
     * @deprecated campi spostati all'interno del prodotto
     */
    private getExecutionType(orderEntryV2: OrderEntryState_v2): ExecutionType {
        const commodityProduct = productFieldSelectorGenerator({
            productSelector: (product) => filterCommodityProducts(product) && product,
            state: orderEntryV2,
        }).next().value;
        const maintenanceProduct = productFieldSelectorGenerator({
            productSelector: (product) => filterMaintenanceProducts(product) && product,
            state: orderEntryV2,
        }).next().value;
        return {
            commodityQuickPassage: commodityProduct?.immediateEffect,
            commodityEstimatedDate: commodityProduct?.effectiveDate,
            maintenanceQuickPassage: maintenanceProduct?.immediateEffect,
            maintenanceEstimatedDate: maintenanceProduct?.effectiveDate,
        };
    }

    private getAttachments(orderEntryV2: OrderEntryState_v2): AttachmentInfo[] {
        const att: AttachmentInfo[] = [];
        if (orderEntryV2.fotoDocumenti?.nomeFronte) {
            att.push({
                id: newGuid(),
                type: AttachmentType.IDENTITY_CARD_FRONT,
                fileName: orderEntryV2.fotoDocumenti?.nomeFronte,
                fileSize: (orderEntryV2.fotoDocumenti?.dimensioneFronte || '') + '',
            });
        }
        if (orderEntryV2.fotoDocumenti?.nomeRetro) {
            att.push({
                id: newGuid(),
                type: AttachmentType.IDENTITY_CARD_BACK,
                fileName: orderEntryV2.fotoDocumenti?.nomeRetro,
                fileSize: (orderEntryV2.fotoDocumenti?.dimensioneRetro || '') + '',
            });
        }
        if (orderEntryV2.selfCertification?.name) {
            att.push({
                id: newGuid(),
                type: AttachmentType.SELF_CERTIFICATION,
                fileName: orderEntryV2.selfCertification?.name,
                fileSize: (orderEntryV2.selfCertification?.dimension || '') + '',
            });
        }
        return att;
    }

    private getAppointment({ appointment }: OrderEntryState_v2): CartToQuoteAppointment {
        return {
            contactFirstName: appointment?.firstName,
            contactLastName: appointment?.lastName,
            contactPrefix: appointment?.prefix,
            contactPhone: appointment?.phone,
            timeSlot: appointment?.timeslot,
            interphoneAvailable: appointment?.interphone?.available,
            presence: appointment?.presence,
            interphoneNotes: appointment?.interphone?.notes,
        };
    }

    private getGasTechnicalDetails(technicalDetails: TechnicalDetails): Partial<GasAdministrativeTechnicalData> {
        return {
            tipologiaPdr: technicalDetails?.gasMeterScope,
            matricolaMisuratore: technicalDetails?.meterSerialNumber,
            cifreMisuratore: technicalDetails?.gasMeterDigits,
            letturaMisuratore: !technicalDetails?.needed?.meterCounter // !autolettura letta dal cliente
                ? technicalDetails?.meterCounter // autolettura confermata da Net@
                : null,
            gruppoMisuratoreIntegrato: technicalDetails?.gasMeterEmbeddedCluster,
            coefficienteCorrettivoC: technicalDetails?.gasMeterC2AdjustmentFactor,
            cifreCorrettore: technicalDetails?.gasMeterAdjustmentDigit,
            matricolaCorrettore: technicalDetails?.gasMeterAdjustmentSerialNumber,
            letturaCorrettore: technicalDetails?.gasMeterAdjustmentNumber,
            cityGate: technicalDetails?.gasCityGate,
            classeDiPrelievo: technicalDetails?.gasWithdrawalClass,
            progressivoVolumiAnnuo: technicalDetails?.gasProgressiveAnnualVolumes,
            istatComune: technicalDetails?.gasIstatCommon,
        };
    }

    private getDistributorVatCode(orderEntryV2: OrderEntryState_v2): string {
        return orderEntryV2.flowType === FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza
            ? orderEntryV2?.products.find((product) => product?.vendor?.taxCode)?.vendor?.taxCode
            : null;
    }

    private getCalculatedPrivacy(orderEntryPrivacy: boolean, d365Privacy: boolean): boolean {
        return !isNil(orderEntryPrivacy) ? orderEntryPrivacy : isBoolean(d365Privacy) ? d365Privacy : null;
    }
}

enum AttachmentType {
    IDENTITY_CARD_FRONT = 'IDENTITY_CARD_FRONT',
    IDENTITY_CARD_BACK = 'IDENTITY_CARD_BACK',
    SELF_CERTIFICATION = 'SELF_CERTIFICATION',
}
